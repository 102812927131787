import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch('/intro.md')
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);

  return (
    <section id="sidebar">
      <section id="intro">
        <Link to="/" className="logo">
          <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
        </Link>
        <header>
          <h2>Fadi Bakoura</h2>
          <p>Senior Software Engineer @ Ledger</p>
        </header>
      </section>

      <section className="blurb">
        <h2>About</h2>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <ul className="actions">
          <li>
            {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
          </li>
        </ul>
      </section>

      <section id="footer">
        <ContactIcons />
        <p className="copyright">adapted from <a href="https://github.com/mldangelo/personal-site">mldangelo</a>.</p>
      </section>
    </section>
  );
};

export default SideBar;
